@font-face {
    font-family: 'Roboto Slab';
    src: url('/external/roboto-slab.ttf');
}

:root {
	--spin: 0deg;
	--background: hsl(0deg, 0%, 10%);
	--foreground: white;
	--color: hsl(300deg, 20%, 20%);
	border-color: var(--foreground);
}

html {
	min-height: 100%;
}

code[class*='language-'],
pre[class*='language-'],
code,
html,
body,
button {
	font-family: 'Fira Code', monospace;
	line-height: initial;
}

address {
	& a {
		display: inline-block;
		margin: 1em;
		white-space: nowrap;
	}
}

a, button, .button, address > article {
    & i {
		vertical-align: top;
		margin-right: 0.25em;
		width: 1em;
		height: 1em;
    }
}

a, button, .button {
	& svg {
		transition-duration: 0.2s;
		transition-property: fill;
	}

	&:hover,
	&:active,
	&:focus {
		& svg {
			fill: var(--background);
		}
	}
}

footer {
	margin: 1em;
	margin-bottom: 0;
	text-align: center;
}

p, .text-body {
	font-family: 'Roboto Slab', serif;
	line-height: 1.5;
}

button, .button {
	background: none;
	border: 1px solid;
	background-color: var(--color);
	padding: 1em;
	&:hover {
		cursor: pointer;
	}
}

a, button, .button, address > article {
	text-decoration: none;
	font-weight: 600;
	color: var(--foreground);
}

a, button, .button {
	border-bottom: 1px solid;
	background-image: linear-gradient(to top, var(--foreground) 0 100%);
	background-repeat: no-repeat;
	background-position: 0 100%;
	background-size: 100% 0;
	transition-duration: 0.2s;
	transition-property: background-size, color;
}

a:hover, button:hover, .button:hover,
a:active, button:active, .button:active,
a:focus, button:focus, .button:focus,
a[aria-current='page'] {
	color: var(--background);
	background-size: 100% 100%;
}

a[aria-current='page'] {
	pointer-events: none;
}

html {
	padding: 0;
	margin: 0;
	color: var(--foreground);
	background-color: var(--background);
}

h1 {
    font-weight: 500;
}

nav > ul {
	list-style: none;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-end;
	& li {
		margin: 0.5rem;
	}
	padding: 1rem;
}

.cd-back {
	position: relative;
	& > svg {
		height: 100%;
		width: 100%;
		position: absolute;
	}

	& .shine {
		transform: rotate(var(--spin));
	}

	& * {
		position: relative;
	}
}

.no-break {
    white-space: nowrap;
}

@media (prefers-color-scheme: light) {
	:root {
		--background: hsl(0deg, 0%, 90%);
		--foreground: black;
		--color: hsl(120deg, 20%, 80%);
	}
}